import { Injectable } from '@angular/core';
import { HttpClient, HttpParams  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
               
  URI:string = 'https://avaluos.itelisoft.click/api-autorama/public/api/vehicle/';
  URL_REPORTES:string = 'https://avaluos.itelisoft.click/avaluos/reportes/public/api/';
  URL_AVALUOS:string = 'https://avaluos.itelisoft.click/avaluos/api-rest/public/api/';

  private apiUrl = 'https://app.intelimotor.com/api/units';
  private apiKey = 'N1BKTkXkUu';
  private apiSecret = '55B3558E95C97A62647D65117F65D49B87943A6938CAC6D18D56F87F69';
  private getAll = 'true';

  constructor(private http:HttpClient) { }

    getVehicles():Observable<any>{

    return this.http.get('https://avaluos.itelisoft.click/api-autorama/public/api/vehicle/intelimotor');
  }

  // getVehicles(): Observable<any>{

  //   const params = new HttpParams()
  //   .set('apiKey', this.apiKey)
  //   .set('apiSecret', this.apiSecret)

  //   ;
  //   return this.http.get<any>(this.apiUrl, { params }).pipe(
  //     map(response => {
  //       // Verifica que 'response' tenga la propiedad 'data'
  //       if (response && Array.isArray(response.data)) {
  //         const filteredData = response.data.filter((item: any) => item.status === 'Activo');
  //         return { ...response, data: filteredData };
  //       } else {
  //         console.error('La respuesta no contiene un arreglo "data".', response);
  //         return response; // Devuelve la respuesta original si no tiene "data"
  //       }
  //     })
  //   );
  //   }


  // Cargar los primeros 20 objetos
  // getFirst20Vehicles(): Observable<any[]> {
  //   const params = new HttpParams()
  //     .set('apiKey', this.apiKey)
  //     .set('apiSecret', this.apiSecret)
  //     .set('pageSize', '20') // Indicar que queremos los primeros 20 objetos
  //     .set('pageNumber', '0'); // Primera página

  //   return this.http.get<any>(this.apiUrl, { params }).pipe(
  //     map(response => response.data || []) // Extraer los datos del response
  //   );
  // }

  // // Cargar los objetos restantes
  // getRemainingVehicles(): Observable<any[]> {
  //   const params = new HttpParams()
  //     .set('apiKey', this.apiKey)
  //     .set('apiSecret', this.apiSecret)
  //     .set('pageSize', '50') // Tamaño del resto
  //     .set('pageNumber', '1'); // Página siguiente

  //   return this.http.get<any>(this.apiUrl, { params }).pipe(
  //     map(response => response.data || []) // Extraer los datos del response
  //   );
  // }

  // // Método para encadenar ambas peticiones
  // getAllVehicles(): Observable<any[]> {
  //   return this.getFirst20Vehicles().pipe(
  //     concatMap(firstBatch => 
  //       this.getRemainingVehicles().pipe(
  //         map(remainingBatch => [...firstBatch, ...remainingBatch]) // Combinar los resultados
  //       )
  //     )
  //   );
  // }

  // return this.http.get('https://app.intelimotor.com/api/units?apiKey=N1BKTkXkUu&apiSecret=55B3558E95C97A62647D65117F65D49B87943A6938CAC6D18D56F87F69&getAll');  }



  getOfferVehicles():Observable<any>{
    return this.http.get(this.URI+'consult/vehicles/offer/portal');
  }
  getVehicle(id:string):Observable<any>{
    // return this.http.get(this.URI+'consult/vehicle/'+id);}
    return this.http.get('https://avaluos.itelisoft.click/api-autorama/public/api/vehicle/serial/'+id);

  }
  
  getVehicleFromAvaluo(serie){
    return this.http.get(this.URL_AVALUOS+'vehicle/serie/'+serie);
  }

  getCertification(id){
    return this.http.get(this.URL_REPORTES+'curriculums/consult/'+id, {
      responseType: "blob",
    });
  }

  /*getVehiclesByIntelimotor(){
    return this.http.
  }*/
}
